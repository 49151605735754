import React, { createContext, useState } from "react"
import { ContentfulSiteSettings } from "../types";

interface Settings {
  settings: ContentfulSiteSettings
  setSettings: any
}

export const SettingsContext: React.Context<Settings> = createContext({
  settings: {},
  setSettings: null,
})

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({})

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider
