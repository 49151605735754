import React, { createContext, useState } from "react";

export const HeaderContext: React.Context = createContext({
  headerColor: "",
  initialHeaderColor: "",
  setHeaderColor: null,
  setInitialHeaderColor: null,
  showHeader: true,
  setShowHeader: null,
});

const HeaderProvider = ({ children }) => {
  const [headerColor, setHeaderColor] = useState("dark");
  const [initialHeaderColor, setInitialHeaderColor] = useState("dark");
  const [showHeader, setShowHeader] = useState(true);

  return (
    <HeaderContext.Provider
      value={{
        headerColor,
        setHeaderColor,
        initialHeaderColor,
        setInitialHeaderColor,
        showHeader,
        setShowHeader,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
